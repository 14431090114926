import React from "react";

export default function Cv() {
  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          Therapeutische Qualifikationen
        </h3>
        <p className="max-w-2xl mt-1 text-sm text-gray-500">
          Zertifikate und Fortbildungen
        </p>
      </div>
      <div className="border-t border-gray-200">
        <dl>
          <div className="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">2022</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              Erwerb der Zusatzqualifikation Gruppenpsychotherapie{" "}
            </dd>
          </div>
        </dl>
      </div>
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          Werdegang
        </h3>
        <p className="max-w-2xl mt-1 text-sm text-gray-500">
          Beruflich und akademisch
        </p>
      </div>
      <div className="border-t border-gray-200">
        <dl>
          <div className="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">2022 – heute</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <b>Psychologische Psychotherapeutin</b> in eigener Praxis
            </dd>
          </div>
          <div className="px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">2022 – heute</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <b>Psychologische Psychotherapeutin </b> bei MindDoc in der
              Online-Therapie
            </dd>
          </div>
          <div className="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">2022</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <b>Approbation als Psychologische Psychotherapeutin</b> in Berlin
              durch das LaGeSo Berlin, Eintrag in das Arztregister der
              Kassenärztlichen Vereinigung Berlin,
              Mitglied der Psychotherapeutenkammer Berlin, Mitglied in der DPTV
              (Deutsche Psychotherapeutenvereinigung).
            </dd>
          </div>
          <div className="px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">2018 – 2022</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              Psychotherapeutische Tätigkeit mit dem{" "}
              <b>
                Schwerpunkt Verhaltenstherapie für Erwachsene in Einzel- und
                Gruppentherapie
              </b>
              am „IVT – Institut für Verhaltenstherapie“ in Berlin
            </dd>
          </div>
          <div className="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">2020 – 2021</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <b>Psychologin</b> in der psychosozialen Beratungsstelle
              „Familienzelt“ in Berlin
            </dd>
          </div>
          <div className="px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">2017 – 2020</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <b>Psychologin</b> in der „Oberhavel Kliniken GmbH“ in Hennigsdorf
            </dd>
          </div>
          <div className="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">2013 – 2017</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <b>Psychologin</b> in der psychosozialen Beratungsstelle
              „Familienzelt“ in Berlin
            </dd>
          </div>
          <div className="px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">2013 – 2016</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <a
                href="https://www.osa.fu-berlin.de/psychologie/perspektiven/master/index.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                <b>
                  Master of Science in klinischer und Gesundheitspsychologie
                </b>{" "}
                an der „Freien Universität Berlin“
              </a>
            </dd>
          </div>
          <div className="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">2007 – 2011</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <b>Bachelor of Science in Psychologie</b> an der „Freien
              Universität Berlin“
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
}
