import React from "react";
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Cv from "../components/cv";

const AboutMePage = () => (
  <Layout>
    <Seo
      title="Psychotherapeutin Antje Mützel"
      description="Psychologische Psychotherapeutin Antje Mützel, approbierte Verhaltenstherapeutin. Fundierte psychologische Ausbildung & psychotherapeutische Tätigkeiten"
    />

    <div className="relative">
      <div className="w-full h-96">
        <StaticImage
          className="w-full h-full"
          src="../assets/images/hero-ueber-mich.jpg"
          alt="Therapeutin auf blauem Sessel sitzend lacht herzlich den Patienten an"
        />
        <div className="absolute top-0 w-full h-full opacity-30 bg-gradient-to-r from-transparent via-black to-transparent"></div>
        <div className="absolute top-0 flex flex-wrap items-center w-full h-full place-items-center">
          <div className="w-full text-center text-white">
            <h1 className="mb-4 lg:text-4xl">Über mich</h1>
            <div className="px-4 sm:text-lg">
              "Vertrauensvolle Zusammenarbeit auf Augenhöhe ist mir wichtig."
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="my-12 boxed max-w-prose generic-page">
      <p>
        Eine wertschätzende Begegnung auf Augenhöhe ist mir das Wichtigste in
        der therapeutischen Beziehung. Daher bin ich auch Verhaltenstherapeutin
        mit Leib und Seele, denn in dieser Fachrichtung ist mir genau das
        möglich. Wir arbeiten transparent und authentisch miteinander, Sie sind
        die Expertin oder der Experte für Ihre ganz individuelle Situation. Die
        Therapie bietet Ihnen einen wertungsfreien offenen Raum für Veränderung
        und Entwicklung. Wir haben die Möglichkeiten, flexibel und
        bedürfnisorientiert zu arbeiten, wobei Motivation und auch Geduld
        gefragt sind, um langfristige psychische Gesundheit zu erreichen.
        Veränderungen brauchen Ihre Mitarbeit und Zeit, ich begleite jeden
        Menschen mit Aufrichtigkeit, Empathie und ganzheitlicher
        Herangehensweise. In der Therapie darf gelacht und geweint werden, es
        dürfen alle Themen besprochen und auch eigene Grenzen gewahrt werden.
      </p>
    </div>
    <div
      className="grid max-w-6xl gap-1 mx-auto md:grid-cols-2 md:h-screen md:gap-48 "
      itemscope
      itemtype="https://schema.org/Person"
    >
      <div className="flex items-center p-4 bg-red-100">
        <StaticImage className="max-w-xs mx-auto transform shadow-md md:shadow-none md:translate-x-32" itemprop="image" src="../assets/images/antje-muetzel-psycho-therapeutin-berlin.jpg" alt="Psychotherapeutin Antje Mützel lächelt freundlich in die Kamera" />
      </div>
      <div className="flex items-center">
        <div className="grid w-full max-w-md gap-2 p-4 mx-auto text-center md:gap-6 md:text-left">
          <div>
            <span className="text-sm" itemprop="jobTitle">
              M. Sc. Psych.
            </span>
            <br />
            <span className="text-2xl tracking-widest bold" itemprop="name">
              Antje Mützel
            </span>
            <br />
            <span
              className=""
              itemprop="hasOccupation"
              itemscope
              itemtype="https://schema.org/Occupation"
            >
              <span itemprop="name">Psychologische Psychotherapeutin</span>
            </span>
          </div>
          <div>
            <address className="not-italic">
              Wiltbergstraße&nbsp;36
              <br />
              13125&nbsp;Berlin
            </address>
          </div>
        </div>
      </div>
    </div>
    <div className="my-12 boxed max-w-prose generic-page">
      <p>
        In meiner bisherigen Arbeit habe ich ambulant (Beratung und Therapie),
        stationär (in einer Klinik) und teilstationär (in einer Tagesklinik)
        Erfahrungen sammeln können. Ich durfte Mütter, Väter und Familien
        beraten, rund um die Themen Schwangerschaft und Geburt eines Kindes –
        mit allen verbundenen Unsicherheiten, Krisen und Erkrankungen die sich
        in dieser besonderen Phase der Veränderung auftun können. In meiner
        bisherigen psychologischen und psychotherapeutischen Arbeit habe ich ein
        breites praktisches Behandlungswissen vielfältiger Störungsbilder
        erworben. Außerdem habe ich diverse ambulante, stationäre und
        teilstationäre Gruppenangebote geleitet, wie beispielsweise
        Psychosegruppen, Depressionsgruppen, Angstgruppen, Suchtgruppen,
        Entspannungsgruppen, soziales Kompetenztraining, verschiedene
        Eltern-Kind-Gruppen sowie Psychotherapiegruppen mit diversen
        Schwerpunkten, wie Interaktion, Emotionsregulation, Kommunikation und
        Stressmanagement.
      </p>
    </div>
    <div className="my-12 boxed max-w-prose generic-page">
      <Cv />
    </div>
  </Layout>
);

export default AboutMePage;
